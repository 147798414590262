<template>
    <div>
        <div id="team-list">
         <!--   <div class="options w-100 py-2">

                <div class="d-inline-block text-center">
                    <a href="#" @click.prevent="tab=1" :class="tab==1 ? 'router-link-exact-active active-line':''">
                        <div class="icon mx-2">
                            <div class="active-line"></div>
                            <img src="/img/moje_karty.png" alt />
                        </div>
                        <div class="mt-1">Intervaly upozornění</div>
                    </a>
                </div>


            </div>

-->
            <div class="list w-100 py-2">
                <h4 class="h6 px-1 pt-1">{{ $t('admin.interval-settings')}}</h4>


                <table class="table table-striped">
                    <tr>
                        <th class="col col-4">Typ</th>
                        <th>{{ $t("motor.options.servis-interval")}}</th>
                        <th>{{ $t("motor.options.mer-interval")}}</th>
                        <th>{{ $t("motor.options.maz-interval")}}</th>
                        <th>{{ $t("motor.options.maz-1-interval")}}</th>

                        <th style="min-width: 150px"></th>
                    </tr>

                    <tr v-if="!addingNewIntervals">
                        <th>
                            <button class="btn btn-sm btn-success mr-1" @click="addNewIntervals">{{$t('admin.interval-settings.add')}}</button>
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr v-else>
                        <th>
                            <input id="inputName1" type="text" class="form-control form-control-sm"
                                v-model.trim="newIntervals.title"/>
                        </th>
                        <th>
                            <input id="inputName2" type="number" min="1" max="2000" class="form-control form-control-sm"
                                v-model.trim="newIntervals.intervalS"/>
                        </th>
                        <th>
                            <input id="inputName3" type="number" min="1" max="2000" class="form-control form-control-sm"
                                v-model.trim="newIntervals.intervalM"/>
                        </th>
                        <th>
                            <input id="inputName4" type="number" min="1" max="2000" class="form-control form-control-sm"
                                v-model.trim="newIntervals.intervalL"/>
                        </th>
                        <th>
                            <input id="inputName5" type="number" min="1" max="2000" class="form-control form-control-sm"
                                   v-model.trim="newIntervals.intervalL1"/>
                        </th>
                        <th class="d-flex">
                            <button class="btn btn-sm btn-success mr-1" @click="saveNewIntervals()">{{$t('admin.insert')}}</button>
                            <button class="btn btn-sm btn-secondary" @click="cancelNewIntervals()">{{ $t('admin.cancel') }}</button>
                        </th>
                    </tr>

                    <tr v-for="i in intervals" :key="i.id">
                        <th>

                            <input id="inputName1b" type="text" :disabled="i.disabled" class="form-control form-control-sm" v-model.trim="i.title"
                               v-if="i.title!='Default'"/>
                          <p v-else>Default</p>
                        </th>
                        <th>
                            <input id="inputName2b" type="number" :disabled="i.disabled" min="1" max="2000" class="form-control form-control-sm"
                                v-model.trim="i.intervalS"
                               />
                        </th>
                        <th>
                            <input id="inputName3b" type="number" :disabled="i.disabled" min="1" max="2000" class="form-control form-control-sm"
                                v-model.trim="i.intervalM"
                                />
                        </th>
                        <th>
                            <input id="inputName4b" type="number" :disabled="i.disabled" min="1" max="2000" class="form-control form-control-sm"
                                v-model.trim="i.intervalL"
                                />
                        </th>
                        <th>
                            <input id="inputName5b" type="number" :disabled="i.disabled" min="1" max="2000" class="form-control form-control-sm"
                                   v-model.trim="i.intervalL1"
                            />
                        </th>
                        <th v-if="i.disabled" class="d-flex">

                            <button :disabled="editedIntervals && editedIntervals.id != i.id" class="btn btn-sm btn-secondary" @click="editIntervals(i.id)">{{$t('admin.edit')}}</button>
                            <button v-if="i.title!='Default'" :disabled="editedIntervals && editedIntervals.id != i.id" class="btn btn-sm btn-outline-danger ml-2" @click="removeInterval(i.id)">{{$t('admin.delete')}}</button>

                        </th>
                        <th v-else class="d-flex">
                            <button class="btn btn-sm btn-success mr-1" @click="saveIntervals(i.id)">{{$t('admin.save')}}</button>
                            <button class="btn btn-sm btn-secondary" @click="cancelIntervals(i.id)">{{ $t('admin.cancel') }}</button>
                        </th>
                    </tr>

                </table>

            </div>


        </div>
    </div>
</template>

<script>
    const axios = require("axios");
    axios.defaults.withCredentials = true

    const moment = require('moment');
    require('moment/locale/cs');
    moment.locale('cs');
    export default {
        name: "IntervalSettings",
        data(){
            return {
                intervals: [
                    {
                        'id': 1,
                        'name': 'Skupina motorů v dole',
                        'intervalS': 365,
                        'intervalM': 128,
                        'intervalL': 65,
                        'disabled': true
                    },
                    {
                        'id': 2,
                        'name': 'Skupina motorů ČOV',
                        'intervalS': 1250,
                        'intervalM': 328,
                        'intervalL': 121,
                        'disabled': true
                    },
                    {
                        'id': 3,
                        'title': 'Testovací motory',
                        'intervalS': 125,
                        'intervalM': 18,
                        'intervalL': 6,
                        'disabled': true
                    },
                ],
                editedIntervals: null,
                addingNewIntervals: false,
                newIntervals:
                {
                    'title': '',
                    'intervalS': 365,
                    'intervalM': 365,
                    'intervalL': 365
                },
                tab:1,

            }
        },
        computed: {
            me() {
                if (this.$store.getters.me)
                    return this.$store.getters.me;
                else
                    return {}
            }
        },
        methods:{

            // loadTeam(){
            //     let account_id=this.$store.getters.me && this.$store.getters.me.default_account_id ? this.$store.getters.me.default_account_id : 'default'
            //     if (account_id) {
            //         axios.get(process.env.VUE_APP_API_ROOT_URL + "/account/" + account_id).then(result => {
            //             console.log('res', result.data)
            //             if (result.status==200){
            //                 this.members = result.data.members
            //                 this.company = result.data.title
            //                 this.account_id = result.data.id
            //             }
            //         })
            //     }
            // },

            editIntervals(id){
                this.intervals.find(i => i.id === id).disabled = false;
                this.editedIntervals = { ...this.intervals.find(i => i.id === id) };
            },

            removeInterval(id){
                if (!confirm('Opravdu smazat? ')) {
                    return
                }
                    for (let i = 0; i < this.intervals.length; i++) {
                        if (this.intervals[i].id == id) {
                            axios.delete(process.env.VUE_APP_API_ROOT_URL + "/engine_types/" + this.intervals[i].id)
                                .then(response => {
                                    if (response.status == 204 || response.status == 201) {
                                        this.intervals.splice(i, 1);
                                    }
                                })
                            break;
                        }



                    }



            },
            saveIntervals(id){
                var d= this.intervals.find(i => i.id === id)
                d.disabled = true;
                // SAVE
                axios.put(process.env.VUE_APP_API_ROOT_URL + "/engine_types/"+d.id, d)
                    .then(response => {
                        if (response.status == 202) {
                            console.log('OK')
                            this.intervals.find(i => i.id === id).disabled = true;
                            this.editedIntervals = null;
                        }
                    })
            },
            cancelIntervals(id){
                this.intervals.find(i => i.id === id).title = this.editedIntervals.title;
                this.intervals.find(i => i.id === id).intervalS = this.editedIntervals.intervalS;
                this.intervals.find(i => i.id === id).intervalM = this.editedIntervals.intervalM;
                this.intervals.find(i => i.id === id).intervalL = this.editedIntervals.intervalL;
                this.intervals.find(i => i.id === id).intervalL1 = this.editedIntervals.intervalL1;
                this.intervals.find(i => i.id === id).disabled = true;
                this.editedIntervals = null;
            },

            addNewIntervals(){
                this.newIntervals.title = '';
                this.newIntervals.intervalS = 365;
                this.newIntervals.intervalM = 365;
                this.newIntervals.intervalL = 365;
                this.newIntervals.intervalL1 = 365;
                this.addingNewIntervals = true;
            },
            saveNewIntervals(){
                //SAVE
                this.newIntervals.disabled = true;


                axios.post(process.env.VUE_APP_API_ROOT_URL + "/engine_types", this.newIntervals)
                    .then(response => {
                        if (response.status == 201) {
                            console.log('OK')
                            var d = response.data
                            this.newIntervals.id = d.id // v databázi
                            d.disabled=true
                            this.intervals.push(d)
                            this.addingNewIntervals = false;
                        }
                    })



                //this.intervals.push( {...this.newIntervals});

            },
            cancelNewIntervals(){
                this.addingNewIntervals = false;
            },

            loadMotorytpes(){
                axios
                    .get(process.env.VUE_APP_API_ROOT_URL + "/engine_types?t="+(new Date().getTime()))
                    .then(response => {
                        if (response.status == 200) {
                            this.intervals = response.data
                            for(let i=0; i<this.intervals.length; i++) this.intervals[i].disabled=true
                        }
                    })
            }

        },



        mounted(){
            this.loadMotorytpes()
        }
    }
</script>

<style scoped>

</style>
